<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                <TableWithCheckbox
                  :dataTable="dataTable"
                  :labels="labels"
                  :count="cpmLoginLogCount"
                ></TableWithCheckbox>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :subdomain="subdomain"
                  :shopId="shopId"
                  :listLength="cpmLoginLogCount"
                  :modulePath="modulePath"
                ></PaginationLog>
              </template>
            </Panel>
            </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixins
import cpmUser from '@/mixins/module/cpmUser';
//helpers
import { formatDateAndTime } from '@/helpers/formatData';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';

export default {
  name: 'AccountUserLog',
  data: function() {
    return {
      pageName: 'アカウント設定',
      labels: [
        { key: 'dateTime', name: '日時' },
        { key: 'email', name: 'ID' },
        { key: 'username', name: 'ユーザー名' },
        { key: 'ipAddress', name: '接続IP' },
      ],
      modulePath: 'settingUserCpm/getCpmLoginLogs'
    };
  },
  computed: {
    ...mapGetters({
      cpmLoginLogList: 'settingUserCpm/cpmLoginLogList',
      cpmLoginLogCount: 'settingUserCpm/cpmLoginLogCount'
    }),
    dataTable() {
      return this.cpmLoginLogList.map((item) => {
        return {
          ...item,
          dateTime: formatDateAndTime(item.createDate),
        };
      });
    },
  },
  components: {
    TableWithCheckbox,
    PaginationLog
  },
  mixins: [cpmUser],
};
</script>
